<template>
    <layout :title="title" back-button="/orders"
            :loading="loading==true">

        <template v-if="order != null">
            <div class="content z-10 -mt-6 p-2 pb-0 shadow-xl" v-if="order != null">
                <div class="mb-4" :class="{'h-64':order.items.length > 3}">
                    <content-box title="Items To Pick" :minimised="order.items.length > 3 ? true : false">
                        <div class="w-full">
                            <div v-for="productLine in order.items" :key="productLine.id">

                                <router-link :to="'/order-product/' + productLine.id">
                                    <div class="py-1">
                                        <div class="border rounded border-gray-400 flex w-full hover:bg-gray-300">
                                            <div class="flex-1 p-3 truncate w-96 md:w-full">
                                                <span class="text-orange-600">{{productLine.bin_location}} </span>
                                                <span class="text-black">{{productLine.SKU}}</span> {{productLine.name}}
                                            </div>
                                            <div>
                                                <template v-if="productLine.itemsPicked == productLine.ordered">
                                                    <div class="box-status-product-line">
                                                        <span class="text-green-600"><font-awesome-icon
                                                                icon="check-circle"/></span>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="p-3">
                                                        Packed: {{productLine.itemsPicked}} of {{productLine.ordered}}
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </content-box>
                </div>
                <div class="w-full overflow-x-scroll overflow-y-visible md:overflow-x-visible md:overflow-visible">
                    <div class="x-scrollable flex text-xs pb-2">
                        <div class="flex-1 pr-2">
                            <content-box title="Order Details">
                                <table class="w-full">
                                    <tr class="border-b">
                                        <td class="w-1/2 font-bold">Delivery</td>
                                        <td class="w-1/2 text-right text-orange-600">{{order.deliveryMethod}}</td>
                                    </tr>
                                    <tr v-for="(value,key) in order.totals" :key="key" class="border-b">
                                        <td class="w-1/2 font-bold">{{key}}</td>
                                        <td class="text-right p-1">£{{formatPrice(value)}}</td>
                                    </tr>
                                </table>
                            </content-box>
                        </div>
                        <div class="flex-1 pr-2">
                            <content-box title="Invoice Address">
                                {{order.invoiceAddress.first_name}} {{order.invoiceAddress.last_name}},<br>
                                {{order.invoiceAddress.organisation_name}}<br>
                                {{order.invoiceAddress.address_line_1}},<br>
                                <template v-if="order.invoiceAddress.address_line_2 != ''">
                                    {{order.invoiceAddress.address_line_2}},<br>
                                </template>
                                {{order.invoiceAddress.city}},<br>
                                {{order.invoiceAddress.countryID}},<br>
                                {{order.invoiceAddress.post_code}}
                            </content-box>
                        </div>
                        <div class="flex-1">
                            <content-box title="Shipping Address">
                                {{order.shippingAddress.first_name}} {{order.shippingAddress.last_name}},<br>
                                {{order.shippingAddress.organisation_name}}<br>
                                {{order.shippingAddress.address_line_1}},<br>
                                <template v-if="order.shippingAddress.address_line_2 != ''">
                                    {{order.shippingAddress.address_line_2}},<br>
                                </template>
                                {{order.shippingAddress.city}},<br>
                                {{order.shippingAddress.countryID}},<br>
                                {{order.shippingAddress.post_code}}
                            </content-box>
                        </div>
                    </div>
                </div>
                <div class="w-full overflow-x-scroll md:overflow-x-visible">
                    <div class="flex" :class="{'x-scrollable':countDifferentNotes>1}">
                        <div v-if="order.customer_notes" class="pr-2 flex-1" v-on:click="verifyNote('customerNotes')">
                            <content-box :colour="notesVerified.customerNotes ? 'success' : 'danger'"
                                         title="Customer Note"
                                         :confirmation-box="true"
                                         :confirmation-answer="notesVerified.customerNotes">
                                    <div v-html="$options.filters.nl2br(order.customer_notes)"></div>
                            </content-box>
                        </div>
                        <div v-if="order.order_notes" class="pr-2 flex-1" v-on:click="verifyNote('orderNotes')">
                            <content-box :colour="notesVerified.orderNotes ? 'success' : 'danger'"
                                         title="Order Note"
                                         :confirmation-box="true"
                                         :confirmation-answer="notesVerified.orderNotes">
                                <div v-html="$options.filters.nl2br(order.order_notes)"></div>
                            </content-box>
                        </div>
                        <div v-if="order.accountNotes.length > 0" class="flex-1"
                             v-on:click="verifyNote('accountNotes')">
                            <content-box :colour="notesVerified.accountNotes ? 'success' : 'danger'"
                                         title="Account Notes" :confirmation-box="true"
                                         :confirmation-answer="notesVerified.accountNotes">
                                <div>
                                    <div v-for="accountNote in order.accountNotes" :key="accountNote.id">
                                        <small>{{accountNote.date_added}}</small>
                                        <div v-html="$options.filters.nl2br(accountNote.details)"></div>
                                    </div>
                                </div>
                            </content-box>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pl-2 pt-2 flex flex-wrap">
                <div class="btn w-full sm:w-56 warning h-36" v-on:click="unCompleteThisOrder"
                     v-if="canUnCompletePicking">
                    <font-awesome-icon icon="people-carry" size="4x" class="p-2"/>
                    <br/>Set status as Picking
                </div>
                <div class="btn w-full sm:w-56 success h-36"
                     :class="{'disabled':!canCompleteOrder}"
                     v-on:click="completeThisOrder">
                    <font-awesome-icon icon="check-circle" size="4x" class="p-2"/>
                    <br/>Complete Order
                </div>
                <div class="btn w-full sm:w-56 warning h-36" v-on:click="completeOrderSticker({order:order})">
                    <font-awesome-icon icon="barcode" size="4x"/>
                    <br/>Print Extra P-label
                </div>
            </div>
        </template>
    </layout>
</template>

<script>
    import {mapActions, mapState, mapGetters} from 'vuex'
    import Layout from "../components/layout";
    import ContentBox from "../components/contentBox";

    export default {
        name: "orders",
        components: {ContentBox, Layout},
        data() {
            return {}
        },
        created() {
            this.getOrder(this.$route.params.id);
        },
        methods: {
            ...mapActions('order', ['getOrder', 'completeOrder', 'uncompletePicking', 'verifyNote']),
            ...mapActions('printer', ['completeOrderSticker']),
            ...mapActions('alert', ['success', 'error']),
            unCompleteThisOrder() {
                if (this.canUnCompletePicking) {
                    this.uncompletePicking(this.$store.state.order.order.id).then(() => {
                        this.success('Order status is now set as picking')
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            },
            completeThisOrder() {
                if (!this.isNotesVerified) {
                    this.error('Order notes have not be verified')
                } else {
                    if (this.canCompleteOrder) {
                        if (this.order.itemsPicked > 0) {
                            this.completeOrderSticker({order: this.order});
                        }
                        this.completeOrder(this.$store.state.order.order.id).then(() => {
                            this.$router.push({path: '/orders'})
                        }).catch((error) => {
                            console.log(error)
                        })
                    }
                }
            },
            formatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
        },
        computed: {
            ...mapState('order', ['order', 'status', 'loading', 'notesVerified']),
            ...mapGetters('order', ['canCompleteOrder', 'canUnCompletePicking', 'isNotesVerified', 'countDifferentNotes']),
            title: function () {
                return this.order != null ? 'Order: ' + this.order.orderID + '<br>Picking Status: ' + this.order.itemsPicked + ' of ' + this.order.itemsRequiredPicking : 'Loading'
            },
        },

    }
</script>

<style lang="scss">
    .box-status-product-line {
        @apply shadow-inner p-2 bg-gray-200 h-full flex items-center justify-center;
    }
</style>